/* global Component, _ */
const centerCostTemplate = Object.freeze({
    "__meta__": {
        "__classname__": "CenterCost",
        "__error_responses__": [],
        "__ismodified__": true,
        "__isnew__": true
    },
    "fields": {
        "Closed": null,
        "Code": null,
        "Comment": null,
        "CustCode": null,
        "CustName": null,
        "DelAddress": null,
        "DelAddressCode": null,
        "FromDay": null,
        "LimitMaxSalesAmounts": null,
        "MaxMonthlySalesAmount": null,
        "MaxSalesAmount": null,
        "MinSalesAmount": null,
        "Name": null,
        "QtyAllowedMonthly": null,
        "RecordSource": null,
        "ToDay": null,
        "createTime": null,
        "createUser": null,
        "internalId": null,
        "syncVersion": null,
        "updateTime": null,
        "updateUser": null,
        "Contacts": [{
            "__meta__": {
                "__classname__": "CenterCostContactRow",
                "__error_responses__": [],
                "__ismodified__": true,
                "__isnew__": true
            },
            "fields": {
                "IsMainContact": null,
                "PersonCode": null,
                "PersonName": null,
                "RowViewNumber": null,
                "internalId": null,
                "masterId": null,
                "rowId": null,
                "rowNr": 0
            },
            "oldFields": {
                "IsMainContact": null,
                "PersonCode": null,
                "PersonName": null,
                "RowViewNumber": null,
                "internalId": null,
                "masterId": null,
                "rowId": null,
                "rowNr": null
            },
            "removedRows": {}
        }
        ],
        "Liberators": [
            {
                "__meta__": {
                    "__classname__": "CenterCostLiberatorRow",
                    "__error_responses__": [],
                    "__ismodified__": true,
                    "__isnew__": true
                },
                "fields": {
                    "CanLiberate": true,
                    "OnlyModified": false,
                    "PersonCode": null,
                    "PersonName": null,
                    "RowViewNumber": null,
                    "internalId": null,
                    "masterId": null,
                    "rowId": null,
                    "rowNr": 0
                },
                "oldFields": {
                    "CanLiberate": null,
                    "OnlyModified": null,
                    "PersonCode": null,
                    "PersonName": null,
                    "RowViewNumber": null,
                    "internalId": null,
                    "masterId": null,
                    "rowId": null,
                    "rowNr": null
                },
                "removedRows": {}
            }
        ],
    },
    "oldFields": {
        "Closed": null,
        "Code": null,
        "Comment": null,
        "CustCode": null,
        "CustName": null,
        "DelAddress": null,
        "DelAddressCode": null,
        "FromDay": null,
        "LimitMaxSalesAmounts": null,
        "MaxMonthlySalesAmount": null,
        "MaxSalesAmount": null,
        "MinSalesAmount": null,
        "Name": null,
        "QtyAllowedMonthly": null,
        "RecordSource": null,
        "ToDay": null,
        "createTime": null,
        "createUser": null,
        "internalId": null,
        "syncVersion": null,
        "updateTime": null,
        "updateUser": null
    },
    "removedRows": {
        "Contacts": [],
        "Liberators": []
    }
});

class recordMatrixComponent extends Component {

    static name() {
        return "recordMatrixComponent";
    }

    static componentName() {
        return "recordMatrixComponent";
    }

    getProps() {
        return  {
            record:{
                type:Object,
                require:true
            },
            headers:{
                type:Object,
                require:true
            },
            rowField:{
                type:String,
                require:true
            },
            afterEditRow:{
                type:Function,
            },
            afterDeleteRow:{
                type:Function,
            }
        };
    }

    data() {
        return {
            personSelected:null,
            personCode:null,
            newRowRecord:{},
            isModified:false
        };
    }

    mounted() {
        return async function () {
            this.createNewRow = this.createNewRow.bind(this);
            this.deleteRow = this.deleteRow.bind(this);
            this.subscribeEvent('editCenterCost', this.resetData);
        };
    }

    resetData() {
        this.personCode = null;
        this.emitEvent(`clearAwesonLinkTo_${this.rowField}__PersonCode__Person`);
    }

    getComputed() {
        return {
            rows:function (){
                //console.log('ROWWWW',this.record,this.rowField);
                return this.record.fields[this.rowField];
            },
            virtualHeaders:function (){
                return Object.keys(this.headers);
            },
        };
    }

    getMethods() {
        return {
            resetData:this.resetData,
            createNewRow:this.createNewRow,
            deleteRow:this.deleteRow,
            updateRow:this.updateRow,
            getRowEditor:this.getRowEditor,
            selectLinkToOptions:this.selectLinkToOptions,
        };
    }

    createNewRow() {
        if(this.personCode) {
            let event = _.cloneDeep(this.personCode);
            let newRowRecord = _.cloneDeep(centerCostTemplate.fields[this.rowField][0]);
            newRowRecord.__meta__.__isnew__ = true;
            newRowRecord.fields.PersonCode = this.personCode.value;
            event.field = "PersonCode";
            event.matriz = this.rowField;
            if(this.afterEditRow)
                this.afterEditRow(newRowRecord, event, true);
            this.emitEvent(`clearAwesonLinkTo_${this.rowField}__PersonCode__Person`);
            this.personCode = null;
        }
    }

    deleteRow(row) {
        this.record.fields[this.rowField]  = this.record.fields[this.rowField].filter(x=>x.fields.internalId != row.fields.internalId);
        if(this.record.removedRows)
            this.record.removedRows[this.rowField].push(row);
        if(this.afterDeleteRow)
            this.afterDeleteRow(row);
    }

    updateRow(row,field) {
        row.__meta__.__ismodified__ = true;
        this.afterEditRow(row,{field:field,linkToData:[]},false);
    }

    getRowEditor(header) {
        if(this.headers[header] && this.headers[header].type)
            return this.headers[header].type;
        return "text";
    }

    selectLinkToOptions(event) {
        this.personSelected = event.value;
        this.personCode = event;
    }

    getTemplate(){
        return `<div class="row" :key="rowField+'__PersonCode'+'Container'">
                <div class="col-6" :key="rowField+'__input_group'" >
                    <awesonLinkToComponent :recordKey="rowField+'__PersonCode'" table="Person" paste="Code" :showField="['Name','LastName']" :selected="personSelected" :filters="{WebUserFlag:1}" @update-value-full="selectLinkToOptions"></awesonLinkToComponent>
                </div>
                <div class="col-6">
                    <button class="btn btn-light" @click="createNewRow"><i class="fas fa-plus"></i> {{tr('Add Person')}}</button>
                </div>
                <div class="col-12">
                    <table class="table response-table">
                      <thead>
                        <tr>
                          <template v-for="header of virtualHeaders">
                                <th scope="col">{{tr(headers[header].label)}}</th>
                          </template>
                          <th scope="col">{{tr('Actions')}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="row of rows" >
                            <tr>
                                 <template v-for="header in virtualHeaders">
                                       <template v-if="getRowEditor(header)=='checkbox'">
                                          <td> <input :type="getRowEditor(header)" v-model="row.fields[header]" class="form-control form-control-sm" @change="(event)=>updateRow(row,header)"></td>
                                       </template>
                                       <template v-else>
                                          <td>{{row.fields[header]}}</td> 
                                       </template>
                                 </template>
                                 <td @click="deleteRow(row)" :title="tr('Delete')"><span class="btn btn-light"><i class="fa fa-trash"></i></span></td>
                            </tr>
                        </template>
                      </tbody>
                    </table>
                </div>
              </div>`;
    }

}

recordMatrixComponent.registerComponent();